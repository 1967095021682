<template>
    <label>
        <div v-if="label" :class="['form-label', { required: required }]">
            {{ label }}
        </div>

        <div class="l-inline l-gap-1 options" :class="{ disabled }">
            <div
                v-for="color in colors"
                :key="color"
                class="color-swatch"
                :class="{
                    'color-swatch--selected': isColorSelected(color),
                    disabled,
                }"
                :style="{
                    background: color,
                    borderColor: color,
                }"
                @click="selectColor(color)"
            />
        </div>
    </label>
</template>

<script>
export default {
    name: 'ColorInput',
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        colors: {
            type: Array,
            default: () => [
                '#000000',
                '#6D4C41',
                '#D32F2F',
                '#EF6C00',
                '#FFA000',
                '#558B2F',
                '#78909C',
                '#74BAF8',
                '#D84ED4',
                '#303F9F',
            ],
        },
        value: {
            type: [String, Array],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        isColorSelected(color) {
            return this.value instanceof Array
                ? this.value.includes(color)
                : this.value === color
        },
        selectColor(color) {
            if (this.disabled) {
                return
            }

            const newValue =
                this.value instanceof Array
                    ? this.value.includes(color)
                        ? this.value.filter(item => item !== color)
                        : [...this.value, color]
                    : color

            this.$emit('input', newValue)
        },
    },
}
</script>

<style lang="scss" scoped>
.options {
    padding: 8px;
    border: $style-border;
    border-radius: 4px;
    background-color: #fff;
}

.color-swatch {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transition: transform 0.15s;

    &:not(.disabled) {
        cursor: pointer;

        &:hover {
            transform: scale(1.2);
        }
    }

    &--selected {
        border: 2px solid;
        box-shadow: inset 0 0 0 2px #fff;
        transform: scale(1.2);
    }
}

.redesigned {
    .options {
        padding: 9px 10px;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    }
}
</style>
